#ind-member {
    padding-top: 50px;
    width: 20%;
}

#ind-member #dp {
    height: 120px;
    width: 120px;
    border-radius: 50%;
    margin: auto;
}

#ind-member #dp>img {
    height: 100%;
    width: 100%;
    border-radius: 50%;
}

#ind-member .name-member {
    margin-top: 12px;
    font-size: 1.1rem;
    text-align: center;
}

#ind-member .social {
    font-size: 1.5rem;
    color: #f3787a;
    margin: 10px 60px
}

#ind-member .social a {
    text-decoration: none;
    color: #f3787a;
}

#ind-member .social a:hover{
    color: #800000;
}

#ind-member .personal-info {
    margin-top: 12px;
    font-size: 1.1rem;
    text-align: center;
    font-weight: 600;
}

#ind-member .personal-exp {
    font-size: 1.1rem;
    text-align: center;
    font-weight: 600;
}

#ind-member .name-member {
    margin-top: 12px;
    font-size: 1.1rem;
    text-align: center;
}

#ind-member .social {
    font-size: 1.5rem;
    color: #f3787a;
    margin: 10px 60px
}

#ind-member .social a {
    text-decoration: none;
    color: #f3787a;
}

#ind-member .social a:hover{
    color: #800000;
}

@media (max-width: 600px) {
    h1 {
        font-size: 1.5rem;
    }

    p {
        font-size: 1rem;
    }

    #ind-member {
        width: 45%;
        text-align: center;
        margin-top: 18px;
    }

    #ind-member .social{
        display: flex;
        flex-direction: row;
        margin: 10px 30px;
    }

    #ind-member .dp {
        height: 100px;
        width: 100px;
        border-radius: 50%;
        margin: auto;
    }

    #ind-member .personal-info {
        font-weight: bold;
    }

    #ind-member .dp>img {
        height: 100%;
        width: 100%;
        border-radius: 50%;
    }
}