#backToTop {
    position: fixed; 
    bottom: 10px; 
    right: 20px;
    z-index: 99; 
    border: none;
    outline: none;
    background-color: #800000;
    color: white;
    cursor: pointer;
    padding: 15px;
    border-radius: 10px;
    font-size: 18px; 
  }