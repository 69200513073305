/* styles.css */
.modal {
    display: none;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.7);
    z-index: 1000;
  }
  
  .modal.open {
    display: block;
  }
  
  .modal-content {
    background-color: #fff;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    padding: 20px;
    object-fit: cover;
  }

  #banner {
    height: 80vh;
    width: 30vw;
  }

  .modal-content #banner > img {
    height: 100%;
    width: 100%;
  }
  
  .close-button {
    position: absolute;
    top: 10px;
    right: 5px;
    cursor: pointer;
  }


  @media (max-width: 600px) {
    #banner {
      height: 50vh;
      width: 80vw;
    }

    .modal-content{
      left: 45%;
    }
}
  